/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserAccount
 */
export interface UserAccount {
    /**
     * 
     * @type {number}
     * @memberof UserAccount
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    location: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserAccount
     */
    readonly sellers: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof UserAccount
     */
    readonly affiliateProfile: number;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    mobileNumber: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    profilePic?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    pendingProfilePic?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserAccount
     */
    profilePicApproved?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    nickname?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserAccount
     */
    dob?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    state?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserAccount
     */
    welcomeEmailSent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    pin?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    postcode?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    locationName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserAccount
     */
    enableSmsNotifications?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    safeExitUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    smsTemplate?: string;
    /**
     * * `escort` - Independent
     * * `agency` - Agency
     * * `brothel` - Brothel
     * * `affiliate` - Affiliate
     * * `customer` - Customer
     * * `unknown` - Unknown
     * @type {string}
     * @memberof UserAccount
     */
    signedupAs: UserAccountSignedupAsEnum;
    /**
     * 
     * @type {Date}
     * @memberof UserAccount
     */
    readonly createdAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof UserAccount
     */
    readonly updatedAt: Date | null;
    /**
     * 
     * @type {number}
     * @memberof UserAccount
     */
    owner: number;
}


/**
 * @export
 */
export const UserAccountSignedupAsEnum = {
    Escort: 'escort',
    Agency: 'agency',
    Brothel: 'brothel',
    Affiliate: 'affiliate',
    Customer: 'customer',
    Unknown: 'unknown'
} as const;
export type UserAccountSignedupAsEnum = typeof UserAccountSignedupAsEnum[keyof typeof UserAccountSignedupAsEnum];


/**
 * Check if a given object implements the UserAccount interface.
 */
export function instanceOfUserAccount(value: object): value is UserAccount {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('location' in value) || value['location'] === undefined) return false;
    if (!('sellers' in value) || value['sellers'] === undefined) return false;
    if (!('affiliateProfile' in value) || value['affiliateProfile'] === undefined) return false;
    if (!('mobileNumber' in value) || value['mobileNumber'] === undefined) return false;
    if (!('address' in value) || value['address'] === undefined) return false;
    if (!('signedupAs' in value) || value['signedupAs'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('owner' in value) || value['owner'] === undefined) return false;
    return true;
}

export function UserAccountFromJSON(json: any): UserAccount {
    return UserAccountFromJSONTyped(json, false);
}

export function UserAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAccount {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'location': json['location'],
        'sellers': json['sellers'],
        'affiliateProfile': json['affiliate_profile'],
        'mobileNumber': json['mobile_number'],
        'profilePic': json['profile_pic'] == null ? undefined : json['profile_pic'],
        'pendingProfilePic': json['pending_profile_pic'] == null ? undefined : json['pending_profile_pic'],
        'profilePicApproved': json['profile_pic_approved'] == null ? undefined : json['profile_pic_approved'],
        'address': json['address'],
        'firstName': json['first_name'] == null ? undefined : json['first_name'],
        'lastName': json['last_name'] == null ? undefined : json['last_name'],
        'nickname': json['nickname'] == null ? undefined : json['nickname'],
        'dob': json['dob'] == null ? undefined : (new Date(json['dob'])),
        'state': json['state'] == null ? undefined : json['state'],
        'welcomeEmailSent': json['welcome_email_sent'] == null ? undefined : json['welcome_email_sent'],
        'pin': json['pin'] == null ? undefined : json['pin'],
        'postcode': json['postcode'] == null ? undefined : json['postcode'],
        'locationName': json['locationName'] == null ? undefined : json['locationName'],
        'enableSmsNotifications': json['enable_sms_notifications'] == null ? undefined : json['enable_sms_notifications'],
        'safeExitUrl': json['safe_exit_url'] == null ? undefined : json['safe_exit_url'],
        'smsTemplate': json['sms_template'] == null ? undefined : json['sms_template'],
        'signedupAs': json['signedup_as'],
        'createdAt': (json['created_at'] == null ? null : new Date(json['created_at'])),
        'updatedAt': (json['updated_at'] == null ? null : new Date(json['updated_at'])),
        'owner': json['owner'],
    };
}

export function UserAccountToJSON(value?: Omit<UserAccount, 'id'|'sellers'|'affiliate_profile'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'location': value['location'],
        'mobile_number': value['mobileNumber'],
        'profile_pic': value['profilePic'],
        'pending_profile_pic': value['pendingProfilePic'],
        'profile_pic_approved': value['profilePicApproved'],
        'address': value['address'],
        'first_name': value['firstName'],
        'last_name': value['lastName'],
        'nickname': value['nickname'],
        'dob': value['dob'] == null ? undefined : ((value['dob'] as any).toISOString().substring(0,10)),
        'state': value['state'],
        'welcome_email_sent': value['welcomeEmailSent'],
        'pin': value['pin'],
        'postcode': value['postcode'],
        'locationName': value['locationName'],
        'enable_sms_notifications': value['enableSmsNotifications'],
        'safe_exit_url': value['safeExitUrl'],
        'sms_template': value['smsTemplate'],
        'signedup_as': value['signedupAs'],
        'owner': value['owner'],
    };
}

