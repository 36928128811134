import Image from "next/image"

export function Loading() {
  return (
    <div className="fixed left-0 top-0 z-20 flex h-screen w-screen items-center justify-center bg-background/80 pt-16 backdrop-blur-lg">
      <Image
        className="hidden animate-pulse dark:block"
        alt="Loading"
        src="/logo-sm-dark.svg"
        width={100}
        height={100}
      />
      <Image
        className="block animate-pulse dark:hidden"
        alt="Loading"
        src="/logo-sm-light.svg"
        width={100}
        height={100}
      />
    </div>
  )
}
